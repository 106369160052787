@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');




/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: gray white;
  font-family: "Inter", sans-serif !important;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid white;
}


/* .MuiDataGrid-checkboxInput {
  color: #9C27B0 !important;
} */
/* .MuiDataGrid-checkboxInput:selection {
  color: #9C27B0 !important;
} */


.MuiDataGrid-row.Mui-selected{
  background-color: rgba(156, 39, 176, 0.08) !important;
}

.MuiDataGrid-row.Mui-selected:hover {
  background-color: rgba(156, 39, 176, 0.08) !important;
}

.MuiTab-textColorInherit{
  color: #667085 !important;
}

.css-cjctlb-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #6941C6 !important;
  font-weight: 500 !important;
font-size: 14px !important;
}

/* .MuiTabs-indicator {
  background-color: #6941C6 !important;
} */


/* Sidebar Styles */

.shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}
.navbar{
  border-radius: 5px;
}
.sidebar{
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 10;
    transition: 0.5s;
}
.sidebar.active{
    left: 0;
}

.sidebar-overlay{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
  
    opacity: 1;
    visibility: visible;
}


/* Form Tabs Styling */
.css-13xfq8m-MuiTabPanel-root {
  padding: 16px 0px !important;
}

.css-19kzrtu {
  padding: 16px 0px !important;
}